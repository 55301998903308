import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"

import homebanner from '../../images/landing/bg3.png';
import Play from '../../images/icons/Play.png';
import Userbackground from '../../images/landing/bg2.jpg';
import bgcircle from '../../images/landing/bgcircle.svg';
import transparency from '../../images/landing/transparency.svg';
import quality from '../../images/landing/quality.svg';
import unbiaseddata from '../../images/landing/unbiased-data.svg';
import satisfaction from '../../images/landing/satisfaction.svg';
import money from '../../images/landing/money.svg';
import './usecase_styles.scss'
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash';
import { UNBIASED_VIDEO, CONTACT_US } from '../../config/constants';
import ContactModal from '../../components/ContactModal';


export default function Usecases() {
    const docs = useFirestore('fl_content', 'usecase');
    const innovations = useFirestore('fl_content', 'innovation');
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeInnavative, setActiveInnavative] = useState(0);
    const [open, setOpen] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    const changeTab = (index) => {
        setActiveIndex(index);
    }

    const onHandleDataAnnotation = async (data, index) => {
        setActiveInnavative(index);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenVideo = () => {
        setOpenVideo(true);
    };

    const handleCloseVideo = () => {
        setOpenVideo(false);
    };

    return (
        <Layout>
            <SEO title="Use Cases" description="" />
            <ContactModal open={open} handleClose={handleClose} />
            <ContactModal open={openVideo} handleClose={handleCloseVideo} source={UNBIASED_VIDEO} />
            <div className="usecase-cont">
                <section className="main-banner">
                    <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                    <Container>
                        <div className="usecase-banner">
                            <Grid container direction="column">
                                <Grid item xs={12} sm={12} >
                                    <h2>INDUSTRY USE CASES</h2>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Grid container className="buutons btn-conts">
                                        <Grid item>
                                            <Button onClick={handleOpenVideo} target="_blank" className="btnActive"><img src={Play} alt="Play" width="20" /> What's Unbiased?</Button>
                                        </Grid>
                                        <Grid item className="btContact">
                                            <Button onClick={handleOpen} target="_blank" className="btnInActive">Contacts Us</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ul>
                                    {
                                        !_.isEmpty(docs) && docs[0].hasOwnProperty("useCases") && docs[0].useCases.map((val, index) => {
                                            return (
                                                <li onClick={() => { changeTab(index) }}><Link className={activeIndex === index && "active"}>{val.title}</Link></li>
                                            )
                                        })
                                    }

                                    {/* <li><Link>Automotive</Link></li>
                                    <li><Link>Financial Services</Link></li>
                                    <li><Link>Retail</Link></li>
                                    <li><Link>Health Care</Link></li> */}
                                </ul>
                            </Grid>
                        </div>
                    </Container>
                </section>

                <section className="services-cont">
                    <Container>
                        {!_.isEmpty(docs) && docs[0].hasOwnProperty("useCases") &&
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <h2>{docs[0].useCases[activeIndex].subtitle}</h2>
                                    <p>{docs[0].useCases[activeIndex].description}</p>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img src={docs[0].useCases[activeIndex].imageLink} className="services-banner" alt="Banner" />
                                </Grid>
                            </Grid>
                        }
                    </Container>
                </section>

                <section className="top-usecases">
                    <Container>
                        <h2>TOP USE CASES FOR {!_.isEmpty(docs) && docs[0].hasOwnProperty("useCases") && docs[0].useCases[activeIndex].subtitle}</h2>
                        <Grid container>
                            {
                                !_.isEmpty(docs) && docs[0].hasOwnProperty("useCases") && docs[0].useCases[activeIndex].hasOwnProperty("topUseCases") && docs[0].useCases[activeIndex].topUseCases.map((val, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div className="box">
                                                <img src={val.location} alt="thumbnail" />
                                                <h4>{val.title}</h4>
                                                <p>{val.summary}</p>
                                                <Link>LEARN MORE</Link>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                </section>

                {/* <section className="innovations"> */}
                <div className="innovationCont">
                    <Container>
                        <h2>{!_.isEmpty(innovations) && innovations[0].heading}</h2>
                        <div className="innovBox">
                            <Grid container>
                                <Grid item xs={12} sm={5}>
                                    <div className="innovListBox">
                                        <ul>
                                            {!_.isEmpty(innovations) && innovations[0].hasOwnProperty('innovationpart') && innovations[0].innovationpart.map((val, index) => {
                                                return (
                                                    <>
                                                        {
                                                            activeInnavative === index ?
                                                                <li className="active">
                                                                    <span><img src={val.iconLocation} width="18" alt="Icon" /></span>
                                                                    <div className="glist">
                                                                        <h5>{val.subheading}</h5>
                                                                        <p>{val.description}</p>
                                                                    </div>
                                                                </li>
                                                                :
                                                                <li onClick={() => onHandleDataAnnotation('projectsRange', index)} style={{ cursor: 'pointer' }}>
                                                                    <span><img src={val.iconLocation} width="16" alt="Icon" /></span>
                                                                    <div className="glist">
                                                                        <h5>{val.subheading}</h5>
                                                                    </div>
                                                                </li>
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="innovImgBox">
                                        {!_.isEmpty(innovations) && innovations[0].hasOwnProperty('innovationpart') &&
                                            <img src={innovations[0].innovationpart[activeInnavative].imageLocation} alt="Innovation" />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                {/* </section> */}

                <section className="whyChoose">
                    <img src={bgcircle} alt="Background" className="bgCircle" />
                    <section container className="why-choose-cont">
                        <h2>Why choose unbiased?</h2>
                        <p>Unbiased is innovating to solve many of the current problems in the Artifical Intelligence & Machine Learning industry such as Transparency, Bias and Quality of Training Data. Unbiased Data Marketplace platform will act as a gateway to Data Annotations, knowledge sharing, collaborations & futuristic innovation.</p>

                        <div className="circleContLarge">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={transparency} alt="Transparency" width="36" />
                                </div>
                                <h6>TRANSPARENCY</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={quality} alt="Quality Assurance" width="36" />
                                </div>
                                <h6>QUALITY ASSURANCE</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={unbiaseddata} alt="Unbiased Data" width="34" />
                                </div>
                                <h6>UNBIASED DATA</h6>
                            </div>
                        </div>

                        <div className="circleContSmall">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={satisfaction} alt="Worker Satisfaction" width="36" />
                                </div>
                                <h6>WORKER SATISFACTION</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={money} alt="Spam Free Accounts" width="36" />
                                </div>
                                <h6>SPAM FREE ACCOUNTS</h6>
                            </div>
                        </div>


                        <Grid container justify="center" className="btn-cont">
                            <Button className="btn-solid"><img src={Play} alt="play" width="20" /> What’s unbiased?</Button>
                            <Button className="btn-bordered">LEARN MORE</Button>
                        </Grid>
                    </section>
                </section>

                <section className="happy-users">
                    <img src={Userbackground} alt="Background" className="happy-user-bg" />
                    <Grid container direction="column" alignItems="center" justify="center" className="formCont">
                        <h2>JOIN THE LIST OF OUR HAPPY USERS</h2>
                        <Button>
                            <img src={Play} alt="play" width="20" />
                            What’s unbiased?
                        </Button>
                    </Grid>
                </section>

            </div>
        </Layout>
    );
}

